.work {
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.workContainer {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
  max-width: 1128px;
  margin: 80px 0;
}

.workIntroduction {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.workIntroTxt {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #888888;
}

.caseStudyListContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.caseStudyList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
}

.caseStudyItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border-radius: 24px;
  width: 50%;
  gap: 24px;
}

.caseStudyListFull {
  align-items: center;
  display: flex;
}

.caseStudyItemFull {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  border-radius: 24px;
  width: 100%;
}

.caseStudyTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 40%;
}

.caseStudyTitle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.caseStudyHeading {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.caseStudyCover {
  margin: 16px 0;
}

.caseStudyCover img {
  width: 100%;
}

.caseStudyCoverFull {
  margin: -40px -40px -40px -40px;
  border-radius: 24px;
  overflow: hidden;
}

.caseStudyCoverFull img {
  width: 100%;
}

.company-logo {
  width: 80px;
  height: 80px;
}

.company-logo img {
  width: 100%;
  border-radius: 12px;
}


/* Media queries */

@media only screen and (max-width: 768px) {
  .caseStudyTitleContainer {
    width: 100%;
    gap: 24px;
  }

  .caseStudyList {
    flex-direction: column;
  }

  .caseStudyItem {
    width: auto;
  }
  .caseStudyItemFull{
    flex-direction: column;
  }

  .caseStudyCoverFull{
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .caseStudyCoverFull img {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .caseStudyList {
    gap: 24px;
  }

  .caseStudyItem {
    padding: 24px;
  }
  
  .caseStudyItemFull {
    padding: 24px;
  }

  .caseStudyTitle h3{
    font-size: 24px;
    line-height: 32px;
  }

  .workContainer {
    margin: 40px 0;
    gap: 40px;
  }

  .workIntroduction {
    gap: 8px;
  }

  .workIntroTxt{
    font-size: 14px;
    line-height: 20px;
  }

  .workIntroSubTxt h2{
    font-size: 32px;
    line-height: 48px;
  }

  .caseStudyListContainer{
    gap: 24px;
  }
}
/* Media queries */