.caseStudyDetail {
  display: flex;
  justify-content: center;
  margin: 76px 0 0 0;
  /* padding: 0 24px; */
}

.caseStudyDetailContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1f3f6;
}

.caseStudyDetailBackground {
  width: 100%;
}

.caseStudyDetailCoverContainer {
  display: flex;
  align-items: center;
  max-width: 1128px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: space-between;
}

.caseStudyDetailTitleContainer {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.caseStudyDetailTitle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.caseStudyDetailCoverImage {
  margin: 0;
}

.caseStudyDetailCoverImage img {
  width: 100%;
  overflow: hidden;
}

.caseStudyDetailIntroContainer {
  display: flex;
  max-width: 1128px;
  margin: 80px auto;
  padding: 0 24px;
  justify-content: space-between;
  gap: 24px;
}

.projectIntro {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 56%;
}

.introDivider {
  width: 1px;
  background-color: #e0e0e0;
  margin: -80px 0;
}

.projectOverview {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.projectOverviewDetail {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.caseStudyDetailContentContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-width: 744px;
  margin: 80px auto;
  padding: 0 24px;
}

.caseStudyDetailContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.caseStudyDetailContentWrapper img {
  margin: 8px 0 16px 0;
}

.caseStudyDetailInnerContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.caseStudyDetailInnerContentWrapper img {
  display: block;
}

.contentDivider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}

.caseStudyDetailClientLogo {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.clientLogoItem {
  width: auto;
  height: 56px;
}

.clientLogoItem img {
  height: 100%;
}

.grey-bg-img {
  background-color: #f1f3f6;
  padding: 16px 16px;
}

.caseStudyStepContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stepContainer {
  display: flex;
  gap: 16px;
}

.stepItem {
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
}

.stepItem1 {
  width: 100%;
}

.stepItem2 {
  width: calc(100% / 2);
}

.stepItem3 {
  width: calc(100% / 3);
}

.stepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.stepNumber span {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

/* Media queries */

@media only screen and (max-width: 768px) {
  .caseStudyDetailIntroContainer {
    flex-direction: column;
    justify-content: center;
    gap: 32px;
  }

  .caseStudyDetailTitleContainer {
    margin: 64px 0 16px 0;
    width: 100%;
  }
  .caseStudyDetailCoverContainer {
    flex-direction: column;
  }
  .projectIntro {
    width: 100%;
  }

  .introDivider {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
    margin: 0 0;
  }
}

@media only screen and (max-width: 480px) {
  .stepContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .stepItem1 {
    width: auto;
  }
  
  .stepItem2 {
    width: auto;
  }

  .stepItem3 {
    width: auto;
  }
}
/* Media queries */
