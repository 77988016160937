@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");

body {
  font-family: "Figtree", sans-serif;
  color: #212121;
  background-color: #f1f3f6;
}

a {
  text-decoration: none;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
  font-size: 44px;
  line-height: 56px;
  font-weight: 700;
}

h3 {
  margin: 0;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}

h4 {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

h5 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

h6 {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}


p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

ul{
  margin: 0;
  padding: 0 0;
  list-style: none;
  background-color: #f4f4f4;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

ul li{
  margin-left: 0;
  list-style: none;
  border-left: 4px solid #e0e0e0;
  padding: 4px 8px;
  margin: 16px;
}

.primary-btn {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  border: 2px solid #212121;
  padding: 10px 16px;
  background-color: #212121;
  font-family: "Figtree", sans-serif;
  cursor: pointer;
}

.secondary-btn {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #212121;
  border-radius: 8px;
  border: 2px solid #212121;
  padding: 10px 16px;
  background-color: white;
  font-family: "Figtree", sans-serif;
  cursor: pointer;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

.rounded-btn {
  border-radius: 24px;
}

.nobg-btn {
  background: none;
  border: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #212121;
  cursor: pointer;
  text-align: center;
  padding: 8px 0;
  font-family: "Figtree", sans-serif;
}

.accent-btn {
  color: #0068ff !important;
}

.hover-effect-btn:hover {
  background-color: #212121;
  color: white;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

.hover-effect-nobg-btn:hover {
  background: rgba(0, 0, 0, 0.16);
  padding: 8px 12px;
  transition-timing-function: ease-in;
  transition: 0.2s;
  border-radius: 8px;
}

.disabled-btn{
  cursor: not-allowed;
  opacity: 0.64;
}

/* padding for button icon */
button .icon {
  padding-left: 8px;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

/* animate button icon */
.animated-btn:hover span {
  padding-left: 16px;
  transition-timing-function: ease-in;
  transition: 0.2s;
}

/* background styling */
.bg-white {
  background-color: white;
}

.bg-black {
  background-color: #212121;
}

.bg-blue{
  background-color: #0068ff;
}

.bg-green {
  background-color: #056938;
}

.bg-pink {
  background-color: #e72887;
}

.bg-darkblue {
  background-color: #013759;
}

.bg-lightblue {
  background-color: #ebf4fe;
}

.bg-dark {
  background-color: #253052;
}

.bg-lightgrey{
  background-color: #f1f3f6;
}
/* background styling */

/* border styling */
.border-blue {
  border: 1.5px solid #0068ff;
}

.border-lightgrey {
  border: 1.5px solid #e0e0e0;
}

.border-green{
  border: 1.5px solid #056938;
}

.border-lightblue{
  border: 1.5px solid #ebf4fe;
}
/* border styling */


/* text color styling */
.txt-white {
  color: white;
}

.txt-blue{
  color: #0068ff;
}

.txt-green{
  color: #056938;
}
/* text color styling */


/* text content styling */
.textContainer{
  display: flex;
  flex-direction: column;
}

.textTitle{
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: #888888;
}

.textData{
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
/* text content styling */

.rounded-img{
  border-radius: 16px;
}

/* popup styling */
.popup-content {
  border-radius: 16px !important;
  width: 320px !important;
  padding: 8px !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  border: none !important;
}

.close-btn{
  color: #888888;
  position: absolute;
  top: 8x;
  right: 8px;
  font-size: 24px;
  cursor: pointer;
}

/* popup styling */